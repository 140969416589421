import { BaseModel } from 'modules/core/models/base-model';

export class AssociationSectorField extends BaseModel {
    id                       = null;
    slug                     = null;
    sector_id                = null;
    performed_test_id        = null;
    sample_collection_id     = null;
    part_zone_application_id = null;
    usage_id                 = null;
    layer_id                 = null;
}
