import { BaseModel } from 'modules/core/models/base-model';

export class AdditionalInformation extends BaseModel {
    id                    = null;
    number                = null;
    type_id               = null;
    scope                 = null;
    description           = null;
    standard              = null;
    referencing_data      = null;
    file_id               = null;
    observations          = null;
    entity_name           = null;
    entity_id             = null;
    concession_name       = null;
    concession_id         = null;
    lot_intervention_name = null;
    lot_intervention_id   = null;
}
