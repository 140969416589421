import { bindable, inject }     from 'aurelia-framework';
import { I18N }                 from 'aurelia-i18n';
import { BaseOrderedListViewModel } from 'base-ordered-list-view-model';
import { FilterFormSchema }     from 'modules/management/dynamic-fields/sample-collections/filter-form-schema';
import { AppContainer }         from 'resources/services/app-container';
import { Downloader }           from 'resources/services/downloader';
import { SampleCollectionsRepository } from './services/repository';

@inject(AppContainer, SampleCollectionsRepository, I18N, Downloader, FilterFormSchema)
export class ListSampleCollections extends BaseOrderedListViewModel {

    listingId = 'management-sample-collections-listing';

    @bindable headerTitle    = 'listing.management.dynamic-fields.sample-collections';
    @bindable newRecordRoute = 'management.dynamic-fields.sample-collections.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.dynamic-fields.sample-collections.manage',
            'management.dynamic-fields.sample-collections.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.canEdit = this.appContainer.authenticatedUser.can(['management.dynamic-fields.sample-collections.manage', 'management.dynamic-fields.sample-collections.edit']);

        await this.loadFullData();
        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            dblClick:        !this.canEdit,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.dynamic-fields.sample-collections.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.dynamic-fields.sample-collections.manage', 'management.dynamic-fields.sample-collections.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.dynamic-fields.sample-collections.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.dynamic-fields.sample-collections.manage', 'management.dynamic-fields.sample-collections.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.dynamic-fields.sample-collections.manage', 'management.dynamic-fields.sample-collections.delete']),
            },
            options:         [
            ],
            buttons:         [
                {
                    label:            'form.button.save-order',
                    icon:             'icon-list-ordered',
                    visible:          this.canEdit,
                    action:           async () => await this.updateOrder(),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'order',
                    name:  'sample_collections.order',
                    title: 'form.field.order',
                    center: this.canEdit,
                    type:   this.canEdit ? 'input' : null,
                    number: true,
                },
                {
                    data:  'name',
                    name:  'sample_collection_translations.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'sample_collections.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

}
